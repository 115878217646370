import { type SectionLocale } from '../types'

export const HOME_LOCALE: SectionLocale = [
  [
    'home_my_profile_link',
    {
      ru: 'Мой профиль',
      en: 'My profile'
    }
  ],
  [
    'home_news_link',
    {
      ru: 'Новости',
      en: 'News'
    }
  ],
  [
    'home_announcements_link',
    {
      ru: 'Объявления',
      en: 'Announcements'
    }
  ],
  [
    'home_announcements_placeholder',
    {
      ru: 'Скоро здесь появятся объявления. Не пропусти!',
      en: 'Announcements will appear here soon. Stay tuned!'
    }
  ],
  [
    'home_birthdays',
    {
      ru: 'Дни рождения',
      en: 'Birthdays'
    }
  ],
  [
    'home_faq_description',
    {
      ru: 'Здесь ты найдешь ответы на частые вопросы и полезную информацию',
      en: 'Here you will find answers to frequently asked questions and useful information'
    }
  ],
  [
    'home_feedback_description',
    {
      ru: 'В разделе обратной связи можно задать вопросы и предложить идеи для улучшений',
      en: 'In the feedback section, you can ask questions and suggest ideas for improvements'
    }
  ],
  [
    'home_feedback_link',
    {
      ru: 'Обратная связь',
      en: 'Feedback'
    }
  ],
  [
    'home_shop_balance_active',
    {
      ru: 'Активный баланс',
      en: 'Active balance'
    }
  ],
  [
    'home_shop_balance_wallet',
    {
      ru: 'Кошелёк',
      en: 'Wallet'
    }
  ],
  [
    'home_shop_link',
    {
      ru: 'Магазин',
      en: 'Shop'
    }
  ],
  [
    'home_coins_notification_hide',
    {
      ru: 'Скрыть',
      en: 'Hide'
    }
  ],
  [
    'home_coins_notification_send',
    {
      ru: 'Отправить коины',
      en: 'Send coins'
    }
  ]
]
