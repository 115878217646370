import { type SectionLocale } from '../types'

export const FAQ_LOCALE: SectionLocale = [
  [
    'faq_delete_question',
    {
      ru: 'Вы уверены, что хотите удалить вопрос?',
      en: 'Are you sure you want to delete this question?'
    }
  ],
  [
    'faq_back_to_list',
    {
      ru: 'Назад к FAQ',
      en: 'Back to FAQ'
    }
  ],
  [
    'faq_page_description',
    {
      ru: `На этой странице собраны ответы на самые частые вопросы, а также инструкции, рекомендации и статья на
                тему оформления документов, банковских аккаунтов и многое другое. Если вы не смогли найти ответ на свой
                вопрос, направьте его через форму`,
      en: `This page contains answers to the most frequently asked questions, as well as instructions,
                recommendations and articles on the topic of document processing, bank accounts and much more. If you
                could not find the answer to your question, send it through the`
    }
  ],
  [
    'faq_page_description_link',
    {
      ru: 'обратной связи',
      en: 'feedback form'
    }
  ],
  [
    'faq_add_item',
    {
      ru: 'Добавить вопрос',
      en: 'Add question'
    }
  ],
  [
    'faq_edit_item',
    {
      ru: 'Редактировать',
      en: 'Edit'
    }
  ],
  [
    'faq_delete_item',
    {
      ru: 'Удалить',
      en: 'Delete'
    }
  ]
]
