import { type SectionLocale } from '../types'

export const HEADER_LOCALE: SectionLocale = [
  [
    'header_title',
    {
      ru: 'Корпоративный портал',
      en: 'Corporate portal'
    }
  ],
  [
    'header_search',
    {
      ru: 'Найти сотрудника',
      en: 'Find an employee'
    }
  ],
  [
    'header_dropdown-info',
    {
      ru: 'Информация',
      en: 'Information'
    }
  ]
]
