import { type SectionLocale } from '../types'

export const INFO_LOCALE: SectionLocale = [
  [
    'info_add_photo',
    {
      ru: 'Загрузить фото',
      en: 'Upload photo'
    }
  ],
  [
    'info_delete_photo',
    {
      ru: 'Удалить фото',
      en: 'Delete photo'
    }
  ],
  [
    'info_edit_photo',
    {
      ru: 'Редактировать фото',
      en: 'Edit photo'
    }
  ]
]
