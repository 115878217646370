import { type SectionLocale } from '../types'

export const AUTH_LOCALE: SectionLocale = [
  [
    'auth_login',
    {
      ru: 'Войти через Паспорт',
      en: 'Login via Passport'
    }
  ]
]
