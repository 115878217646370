import { type SectionLocale } from '../types'

export const SIGNOUT_POPUP_LOCALE: SectionLocale = [
  [
    'signout_popup_question',
    {
      ru: 'Вы уверены, что хотите выйти?',
      en: 'Are you sure you want to sign out?'
    }
  ]
]
