import { type SectionLocale } from '../types'

export const NEWS_LOCALE: SectionLocale = [
  [
    'news_delete_question',
    {
      ru: 'Вы уверены, что хотите удалить новость?',
      en: 'Are you sure you want to delete this news?'
    }
  ],
  [
    'news_search_not_found',
    {
      ru: 'Новость не найдена',
      en: 'News not found'
    }
  ],
  [
    'news_add_item',
    {
      ru: 'Добавить новость',
      en: 'Add news'
    }
  ],
  [
    'news_search_placeholder',
    {
      ru: 'Поиск новости',
      en: 'Search news'
    }
  ],
  [
    'news_edit_item',
    {
      ru: 'Редактировать',
      en: 'Edit'
    }
  ],
  [
    'news_delete_item',
    {
      ru: 'Удалить',
      en: 'Delete'
    }
  ],
  [
    'news_back_to_list',
    {
      ru: 'Назад к новостям',
      en: 'Back to news'
    }
  ],
  [
    'news_edit_title',
    {
      ru: 'Заголовок новости',
      en: 'News title'
    }
  ]
]
