import { type SectionLocale } from '../types'

export const ROLES_CONTROL_LOCALE: SectionLocale = [
  [
    'roles_control_page_title',
    {
      ru: 'Управление ролями',
      en: 'Roles control'
    }
  ],
  [
    'roles_control_set_role',
    {
      ru: 'Назначить роль',
      en: 'Assign'
    }
  ],
  [
    'roles_control_admin',
    {
      ru: 'Администратор',
      en: 'Admin'
    }
  ],
  [
    'roles_control_content_manager',
    {
      ru: 'Контент-менеджер',
      en: 'Content manager'
    }
  ],
  [
    'roles_control_shop_manager',
    {
      ru: 'Админ. корп. магазина',
      en: 'Shop manager'
    }
  ],
  [
    'roles_control_employee',
    {
      ru: 'Сотрудник',
      en: 'Employee'
    }
  ],
  [
    'roles_control_all',
    {
      ru: 'Все',
      en: 'All'
    }
  ],
  [
    'roles_base_filter',
    {
      ru: 'Группы ролей',
      en: 'Role groups'
    }
  ],
  [
    'roles_unset',
    {
      ru: 'Выберите роль',
      en: 'Select role'
    }
  ],
  [
    'roles_changed_successfully',
    {
      ru: 'Роль успешно изменена',
      en: 'Role changed successfully'
    }
  ]
]
