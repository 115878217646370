import { type SectionLocale } from '../types'

import { AUTH_LOCALE } from './auth'
import { BASE_LOCALE } from './base'
import { EVENTS_LOCALE } from './events'
import { FAQ_LOCALE } from './faq'
import { FEEDBACK_LOCALE } from './feedback'
import { HEADER_LOCALE } from './header'
import { HOME_LOCALE } from './home'
import { INFO_LOCALE } from './info'
import { LOCATION_LOCALE } from './location'
import { NAVIGATION_LOCALE } from './navigation'
import { NEWS_LOCALE } from './news'
import { PROFILES_LOCALE } from './profiles'
import { ROLES_CONTROL_LOCALE } from './rolesControl'
import { SHOP_LOCALE } from './shop'
import { SIGNOUT_POPUP_LOCALE } from './signoutPopup'

const LOCALES: SectionLocale = [
  ...BASE_LOCALE,
  ...HOME_LOCALE,
  ...HEADER_LOCALE,
  ...SIGNOUT_POPUP_LOCALE,
  ...FAQ_LOCALE,
  ...NEWS_LOCALE,
  ...EVENTS_LOCALE,
  ...SHOP_LOCALE,
  ...FEEDBACK_LOCALE,
  ...PROFILES_LOCALE,
  ...ROLES_CONTROL_LOCALE,
  ...AUTH_LOCALE,
  ...INFO_LOCALE,
  ...LOCATION_LOCALE,
  ...NAVIGATION_LOCALE
]

const getMappedLocales = () => {
  const locales: { ru: Record<string, string>; en: Record<string, string> } = {
    ru: {},
    en: {}
  }
  LOCALES.forEach(([key, value]) => {
    locales.ru[key] = value.ru
    locales.en[key] = value.en
  })
  return locales
}
const messages = getMappedLocales()
export default messages
