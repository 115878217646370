import { type SectionLocale } from '../types'

export const BASE_LOCALE: SectionLocale = [
  [
    'base_yes',
    {
      ru: 'Да',
      en: 'Yes'
    }
  ],
  [
    'base_no',
    {
      ru: 'Нет',
      en: 'No'
    }
  ],
  [
    'base_delete',
    {
      ru: 'Удалить',
      en: 'Delete'
    }
  ],
  [
    'base_leave_items',
    {
      ru: 'Оставить',
      en: 'Leave'
    }
  ],
  [
    'base_canel_item_editing',
    {
      ru: 'Есть несохраненные изменения. Сохранить?',
      en: 'There are unsaved changes. Save?'
    }
  ],
  [
    'base_sort',
    {
      ru: 'Сортировать',
      en: 'Sort'
    }
  ],
  [
    'base_sort_by_default',
    {
      ru: 'По умолчанию',
      en: 'Default'
    }
  ],
  [
    'base_sort_by_name',
    {
      ru: 'Название',
      en: 'Name'
    }
  ],
  [
    'base_sort_by_price',
    {
      ru: 'Цена',
      en: 'Price'
    }
  ],
  [
    'base_sort_by_popularity',
    {
      ru: 'Популярность',
      en: 'Popularity'
    }
  ],
  [
    'base_balance',
    {
      ru: 'Баланс',
      en: 'Balance'
    }
  ],
  [
    'balance_history_empty',
    {
      ru: 'История пуста',
      en: 'History is empty'
    }
  ],
  [
    'balance_default',
    {
      ru: 'Баланс кошелька',
      en: 'Wallet balance'
    }
  ],
  [
    'balance_active',
    {
      ru: 'Баланс для передачи',
      en: 'Active balance'
    }
  ],
  [
    'base_save',
    {
      ru: 'Сохранить',
      en: 'Save'
    }
  ],
  [
    'base_cancel',
    {
      ru: 'Отменить',
      en: 'Cancel'
    }
  ],
  [
    'base_incorrect_data',
    {
      ru: 'Некорректные данные',
      en: 'Incorrect data'
    }
  ],
  [
    'base_required_field',
    {
      ru: 'Это поле обязательно',
      en: 'This field is required'
    }
  ],
  [
    'base_incorrect_email',
    {
      ru: 'Некорректный email',
      en: 'Incorrect email'
    }
  ],
  [
    'base_something_went_wrong',
    {
      ru: 'Что-то пошло не так',
      en: 'Something went wrong'
    }
  ],
  [
    'base_search',
    {
      ru: 'Поиск',
      en: 'Search'
    }
  ],
  [
    'max_img_size_text_5mb',
    {
      ru: 'Размер одного изображения не должен превышать 5 МБ',
      en: 'The size of one image should not exceed 5 MB'
    }
  ],
  [
    'upload_imgs',
    {
      ru: 'Загрузить изображения',
      en: 'Upload images'
    }
  ],
  [
    'base_signout',
    {
      ru: 'Выход',
      en: 'Sign out'
    }
  ]
]
