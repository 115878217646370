import { type SectionLocale } from '../types'

export const EVENTS_LOCALE: SectionLocale = [
  [
    'event_delete_question',
    {
      ru: 'Вы уверены, что хотите удалить объявление?',
      en: 'Are you sure you want to delete this event?'
    }
  ],
  [
    'events_search_not_found',
    {
      ru: 'Объявления не найдены',
      en: 'Events not found'
    }
  ],
  [
    'events_search_placeholder',
    {
      ru: 'Поиск объявления',
      en: 'Search announcement'
    }
  ],
  [
    'events_add_item',
    {
      ru: 'Добавить объявление',
      en: 'Add announcement'
    }
  ],
  [
    'events_back_to_list',
    {
      ru: 'Вернуться к объявлениям',
      en: 'Back to announcements'
    }
  ],
  [
    'events_edit_item',
    {
      ru: 'Редактировать',
      en: 'Edit'
    }
  ],
  [
    'events_delete_item',
    {
      ru: 'Удалить',
      en: 'Delete'
    }
  ],
  [
    'events_edit_title',
    {
      ru: 'Заголовок объявления',
      en: 'Announcement title'
    }
  ]
]
