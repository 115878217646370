import type { SectionLocale } from '../types'

export const NAVIGATION_LOCALE: SectionLocale = [
  [
    'navigation_group_company',
    {
      ru: 'Компания',
      en: 'Company'
    }
  ],
  [
    'navigation_group_personal',
    {
      ru: 'Моё',
      en: 'Personal'
    }
  ],
  [
    'navigation_group_hr',
    {
      ru: 'HR',
      en: 'HR'
    }
  ],
  [
    'navigation_item_home',
    {
      ru: 'Главная',
      en: 'Home'
    }
  ],
  [
    'navigation_item_news',
    {
      ru: 'Новости',
      en: 'News'
    }
  ],
  [
    'navigation_item_announcements',
    {
      ru: 'Объявления',
      en: 'Announcements'
    }
  ],
  [
    'navigation_item_employees',
    {
      ru: 'Сотрудники',
      en: 'Employees'
    }
  ],
  [
    'navigation_item_profile',
    {
      ru: 'Профиль',
      en: 'Profile'
    }
  ],
  [
    'navigation_item_shop',
    {
      ru: 'Магазин',
      en: 'Shop'
    }
  ],
  [
    'navigation_item_orders',
    {
      ru: 'Мои заказы',
      en: 'My orders'
    }
  ],
  [
    'navigation_item_tags',
    {
      ru: 'Управление тэгами',
      en: 'Tags control'
    }
  ],
  [
    'navigation_item_faq',
    {
      ru: 'FAQ',
      en: 'FAQ'
    }
  ],
  [
    'navigation_item_feedback',
    {
      ru: 'Обратная связь',
      en: 'Feedback'
    }
  ],
  [
    'navigation_item_feedback_control',
    {
      ru: 'Управление обратной связью',
      en: 'Feedback control'
    }
  ],
  [
    'navigation_item_roles_control',
    {
      ru: 'Управление ролями',
      en: 'Roles control'
    }
  ]
]
