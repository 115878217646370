import type { SectionLocale } from '../types'

export const LOCATION_LOCALE: SectionLocale = [
  [
    'location_country_placeholder',
    {
      ru: 'Выберите страну',
      en: 'Choose country'
    }
  ],
  [
    'location_exclude',
    {
      ru: 'Не показывать товар для:',
      en: 'Do not show item for:'
    }
  ],
  [
    'location_country',
    {
      ru: 'Страна',
      en: 'Country'
    }
  ],
  [
    'location_city',
    {
      ru: 'Город',
      en: 'City'
    }
  ],
  [
    'location_office',
    {
      ru: 'Офис',
      en: 'Office'
    }
  ],
  [
    'location_select_all_offices',
    {
      ru: 'Выбрать все офисы в этой локации',
      en: 'Select all offices in this location'
    }
  ],
  [
    'location_add',
    {
      ru: 'Добавить локацию',
      en: 'Add location'
    }
  ],
  [
    'location_add_country',
    {
      ru: 'Добавить страну',
      en: 'Add country'
    }
  ],
  [
    'location_title',
    {
      ru: 'Офисы (локации)',
      en: 'Offices (locations)'
    }
  ],
  [
    'location_delete_question',
    {
      ru: 'Удалить локацию?',
      en: 'Delete location?'
    }
  ],
  [
    'location_select_error',
    {
      ru: 'Локация уже выбрана',
      en: 'Location already selected'
    }
  ],
  [
    'location_error_select_city',
    {
      ru: 'Выберите город',
      en: 'Select city'
    }
  ],
  [
    'location_error_select_office',
    {
      ru: 'Выберите офис',
      en: 'Select office'
    }
  ],
  [
    'location_select_all',
    {
      ru: 'Выбрать все',
      en: 'Select all'
    }
  ],
  [
    'location_all',
    {
      ru: 'Все локации',
      en: 'All locations'
    }
  ]
]
