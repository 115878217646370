import { type SectionLocale } from '../types'

export const FEEDBACK_LOCALE: SectionLocale = [
  [
    'feedback_delete_email_question',
    {
      ru: 'Вы уверены, что хотите удалить почту?',
      en: 'Are you sure you want to delete the email?'
    }
  ],
  [
    'feedback_delete_type_question',
    {
      ru: 'Вы уверены, что хотите удалить тип обратной связи?',
      en: 'Are you sure you want to delete the type of feedback?'
    }
  ],
  [
    'feedback_control_reset_question',
    {
      ru: 'Есть несохранённые изменения. Сохранить?',
      en: 'There are unsaved changes. Save?'
    }
  ],
  [
    'feedback_notification_success',
    {
      ru: 'Сообщение отправлено',
      en: 'Message sent'
    }
  ],
  [
    'feedback_select_type',
    {
      ru: 'Выбрать тип обращения',
      en: 'Select the type of appeal'
    }
  ],
  [
    'feedback_edit_subject',
    {
      ru: 'Тема обращения',
      en: 'Subject of the appeal'
    }
  ],
  [
    'feedback_edit_email',
    {
      ru: 'Ваш Email',
      en: 'Your Email'
    }
  ],
  [
    'feedback_edit_message',
    {
      ru: 'Текст обращения',
      en: 'Text of the appeal'
    }
  ],
  [
    'feedback_emails_list_title',
    {
      ru: 'Почта для получения обратной связи',
      en: 'Email for feedback'
    }
  ],
  [
    'feedback_types_title',
    {
      ru: 'Типы обратной связи',
      en: 'Types of feedback'
    }
  ],
  [
    'feedback_add_email',
    {
      ru: 'Добавить почту',
      en: 'Add email'
    }
  ],
  [
    'feedback_add_type',
    {
      ru: 'Добавить тип',
      en: 'Add type'
    }
  ],
  [
    'feedback_delete_item',
    {
      ru: 'Удалить',
      en: 'Delete'
    }
  ]
]
