import { type SectionLocale } from '../types'

export const SHOP_LOCALE: SectionLocale = [
  [
    'shop_delete_item_question',
    {
      ru: 'Вы уверены, что хотите удалить товар?',
      en: 'Are you sure you want to delete this item?'
    }
  ],
  [
    'shop_delete_attribute_question',
    {
      ru: 'Вы уверены, что хотите удалить характеристику?',
      en: 'Are you sure you want to delete this attribute?'
    }
  ],
  [
    'shop_clear_cart_question',
    {
      ru: 'Все товары будут удалены из корзины',
      en: 'All items will be deleted from the cart'
    }
  ],
  [
    'shop_stock_control',
    {
      ru: 'Управление наличием',
      en: 'Stock control'
    }
  ],
  [
    'shop_go_to_stock_question',
    {
      ru: 'Перейти к управлению наличием?',
      en: 'Go to stock control?'
    }
  ],
  [
    'shop_add_attribute',
    {
      ru: 'Добавить характеристику',
      en: 'Add attribute'
    }
  ],
  [
    'shop_add_option',
    {
      ru: 'Добавить опцию',
      en: 'Add option'
    }
  ],
  [
    'shop_add_item',
    {
      ru: 'Добавить товар',
      en: 'Add item'
    }
  ],
  [
    'cart_out_of_stock',
    {
      ru: 'Товаров нет в наличии',
      en: 'Out of stock'
    }
  ],
  [
    'shop_item_not_found',
    {
      ru: 'Товар не найден',
      en: 'Item not found'
    }
  ],
  [
    'shop_item_out_of_stock',
    {
      ru: 'Товара нет в наличии',
      en: 'The product is out of stock'
    }
  ],
  [
    'shop-item__description',
    {
      ru: 'Описание',
      en: 'Description'
    }
  ],
  [
    'shop-item__price',
    {
      ru: 'Цена',
      en: 'Price'
    }
  ],
  [
    'shop-item__buy-count-base',
    {
      ru: 'Товар купили:',
      en: 'Purchased:'
    }
  ],
  [
    'order_state_waiting',
    {
      ru: 'Ожидает подтверждения',
      en: 'Waiting for confirmation'
    }
  ],
  [
    'order_state_confirmed',
    {
      ru: 'Подтверждён',
      en: 'Confirmed'
    }
  ],
  [
    'order_state_shipped',
    {
      ru: 'Отправлен',
      en: 'Shipped'
    }
  ],
  [
    'order_state_completed',
    {
      ru: 'Доставлен',
      en: 'Completed'
    }
  ],
  [
    'order_state_cancelled',
    {
      ru: 'Отменён',
      en: 'Cancelled'
    }
  ],
  [
    'back_to_shop',
    {
      ru: 'Назад в магазин',
      en: 'Back to shop'
    }
  ],
  [
    'back_to_product',
    {
      ru: 'Вернуться к карточке товара',
      en: 'Back to product'
    }
  ],
  [
    'back_to_orders',
    {
      ru: 'Назад в заказы',
      en: 'Back to orders'
    }
  ],
  [
    'back_to_cart',
    {
      ru: 'Назад в корзину',
      en: 'Back to cart'
    }
  ],
  [
    'orders_badge',
    {
      ru: 'Заказы',
      en: 'Orders'
    }
  ],
  [
    'my_orders_badge',
    {
      ru: 'Мои заказы',
      en: 'My orders'
    }
  ],
  [
    'shop_order',
    {
      ru: 'Заказ',
      en: 'Order'
    }
  ],
  [
    'cancel_order',
    {
      ru: 'Отменить заказ',
      en: 'Cancel order'
    }
  ],
  [
    'cancel_order_question',
    {
      ru: 'Вы уверены, что хотите отменить заказ?',
      en: 'Are you sure you want to cancel the order?'
    }
  ],
  [
    'order_from',
    {
      ru: 'От',
      en: 'Dated'
    }
  ],
  [
    'cart_empty',
    {
      ru: 'Ваша корзина пуста',
      en: 'Your cart is empty'
    }
  ],
  [
    'orders_empty',
    {
      ru: 'Список заказов пуст',
      en: 'Orders list is empty'
    }
  ],
  [
    'orders_not_found',
    {
      ru: 'Заказы не найдены',
      en: 'Orders not found'
    }
  ],
  [
    'orders_track',
    {
      ru: 'Отследить',
      en: 'Track'
    }
  ],
  [
    'orders_search_placeholder',
    {
      ru: 'Поиск заказов',
      en: 'Search orders'
    }
  ],
  [
    'orders_user_deactivated',
    {
      ru: 'Деактивирован',
      en: 'Deactivated'
    }
  ],
  [
    'shop_in_stock',
    {
      ru: 'В наличии',
      en: 'In stock'
    }
  ],
  [
    'shop_search_placeholder',
    {
      ru: 'Поиск',
      en: 'Search'
    }
  ],
  [
    'shop_edit_item',
    {
      ru: 'Редактировать',
      en: 'Edit'
    }
  ],
  [
    'shop_delete_item',
    {
      ru: 'Удалить',
      en: 'Delete'
    }
  ],
  [
    'shop_get_item',
    {
      ru: 'Получить',
      en: 'Get'
    }
  ],
  [
    'shop_edit_title',
    {
      ru: 'Название товара',
      en: 'Item title'
    }
  ],
  [
    'shop_edit_attribute',
    {
      ru: 'Характеристика',
      en: 'Attribute'
    }
  ],
  [
    'shop_edit_option',
    {
      ru: 'Опция',
      en: 'Option'
    }
  ],
  [
    'transaction_comment',
    {
      ru: 'Комментарий',
      en: 'Comment'
    }
  ],
  [
    'transaction_type_deposit',
    {
      ru: 'Ежемесячные начисления',
      en: 'Monthly accruals'
    }
  ],
  [
    'transaction_type_purchase',
    {
      ru: 'Покупка',
      en: 'Purchase'
    }
  ],
  [
    'transaction_type_refund',
    {
      ru: 'Возврат',
      en: 'Refund'
    }
  ],
  [
    'transaction_type_transfer_out',
    {
      ru: 'Начисление другому сотруднику',
      en: 'Accruals to another employee'
    }
  ],
  [
    'transaction_type_transfer_in',
    {
      ru: 'Начисление от другого сотрудника',
      en: 'Accruals from another employee'
    }
  ],
  [
    'cart_total',
    {
      ru: 'Сумма заказа',
      en: 'Total'
    }
  ],
  [
    'cart_heading',
    {
      ru: 'Корзина',
      en: 'Cart'
    }
  ],
  [
    'cart_order',
    {
      ru: 'Заказать',
      en: 'Order'
    }
  ],
  [
    'cart_clear',
    {
      ru: 'Очистить корзину',
      en: 'Clear cart'
    }
  ],
  [
    'tags_add-label',
    {
      ru: '+ Добавить тэг',
      en: '+ Add tag'
    }
  ],
  [
    'tags_select-placeholder',
    {
      ru: 'Выберите тэг',
      en: 'Select tag'
    }
  ],
  [
    'tags_all-items',
    {
      ru: 'Все товары',
      en: 'All items'
    }
  ],
  [
    'tags_delete_tag_question',
    {
      ru: 'Вы уверены, что хотите удалить тэг?',
      en: 'Are you sure you want to delete the tag?'
    }
  ],
  [
    'tags_reset_question',
    {
      ru: 'Есть несохранённые изменения. Сохранить?',
      en: 'There are unsaved changes. Save?'
    }
  ],
  [
    'shop_suggest_button',
    {
      ru: 'Предложи свой мерч',
      en: 'Suggest your merch'
    }
  ]
]
