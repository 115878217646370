import { type SectionLocale } from '../types'

export const PROFILES_LOCALE: SectionLocale = [
  [
    'profiles_thank',
    {
      ru: 'Поблагодарить',
      en: 'Thank'
    }
  ],
  [
    'profiles_info',
    {
      ru: 'Информация',
      en: 'Information'
    }
  ],
  [
    'profiles_main',
    {
      ru: 'Основное',
      en: 'Main'
    }
  ],
  [
    'profiles_work',
    {
      ru: 'О работе',
      en: 'Work'
    }
  ],
  [
    'profiles_contacts',
    {
      ru: 'Контакты',
      en: 'Contacts'
    }
  ],
  [
    'profiles_additions',
    {
      ru: 'Дополнительно',
      en: 'Addition'
    }
  ],
  [
    'profiles_firstname',
    {
      ru: 'Имя',
      en: 'First Name'
    }
  ],
  [
    'profiles_lastname',
    {
      ru: 'Фамилия',
      en: 'Last Name'
    }
  ],
  [
    'profiles_birthdate',
    {
      ru: 'Дата рождения',
      en: 'Date of Birth'
    }
  ],
  [
    'profiles_boss',
    {
      ru: 'Руководитель',
      en: 'Head of department'
    }
  ],
  [
    'profiles_position',
    {
      ru: 'Должность',
      en: 'Position'
    }
  ],
  [
    'profiles_department',
    {
      ru: 'Отдел (подразделение)',
      en: 'Department (division)'
    }
  ],
  [
    'profiles_back',
    {
      ru: 'Назад к сотрудникам',
      en: 'Back to employees'
    }
  ],
  [
    'profiles_search_not_found',
    {
      ru: 'Сотрудники не найдены',
      en: 'Employees not found'
    }
  ],
  [
    'profiles_location',
    {
      ru: 'Локация',
      en: 'Location'
    }
  ],
  [
    'profiles_office',
    {
      ru: 'Офис',
      en: 'Office'
    }
  ],
  [
    'profiles_phone',
    {
      ru: 'Телефон',
      en: 'Phone'
    }
  ],
  [
    'profiles_additional_phone',
    {
      ru: 'Доп. телефон',
      en: 'Additional phone'
    }
  ],
  [
    'profiles_telegram',
    {
      ru: 'Telegram',
      en: 'Telegram'
    }
  ],
  [
    'profile_info_no_data',
    {
      ru: 'Нет данных',
      en: 'No data'
    }
  ],
  [
    'thanks_send',
    {
      ru: 'Отправить',
      en: 'Send'
    }
  ],
  [
    'thanks_amount',
    {
      ru: 'Количество',
      en: 'Amount'
    }
  ],
  [
    'thanks_to',
    {
      ru: 'Кому',
      en: 'To'
    }
  ],
  [
    'thanks_category',
    {
      ru: 'Категория',
      en: 'Category'
    }
  ],
  [
    'thanks_comment',
    {
      ru: 'Комментарий',
      en: 'Comment'
    }
  ],
  [
    'thanks_not_enough',
    {
      ru: 'У вас недостаточно средств для благодарности',
      en: 'Not enough coins'
    }
  ],
  [
    'thanks_amount_max',
    {
      ru: 'Число должно быть 1 - 100',
      en: 'Amount should be 1 - 100'
    }
  ],
  [
    'thanks_for_help',
    {
      ru: 'Благодарность за помощь',
      en: 'Thanks for the help'
    }
  ],
  [
    'thanks_mentoring',
    {
      ru: 'Наставничество',
      en: 'Mentoring'
    }
  ],
  [
    'thanks_high_quality',
    {
      ru: 'За высокое качество работы',
      en: 'For the high quality work'
    }
  ],
  [
    'thanks_congratulations',
    {
      ru: 'Поздравление',
      en: 'Congratulations'
    }
  ],
  [
    'profile_delete_photo_question',
    {
      ru: 'Удалить фото?',
      en: 'Delete photo?'
    }
  ],
  [
    'profiles_search_placeholder',
    {
      ru: 'Поиск сотрудников',
      en: 'Search employee'
    }
  ]
]
